import optionData from '@/assets/js/optionData';
import {
  fetchTaskSupplementPage,
  fetchTaskSupplementDel,
  fetchTaskSupplementStart,
  fetchTaskSupplementStop,
} from '@/api/DDJ/task';
import { fetchCountryList } from '@/api/DDJ/country';
import { getAdvertiserList } from '@/api/DDJ/advertiser/index';
import { fetchOfferSubAff, handleOfferSubAffUpdate } from '@/api/DDJ/offer';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [
          {
            label: 'formoreWorth',
            value: 'formoreWorth',
          },
        ],
      },
      list: [
        {
          offerId: '1',
        },
      ],
      diallog: {
        more: false,
      },
      loading: {
        list: false,
        startBtn: false,
        stopBtn: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      pagesTotal: 0,
      isShowPage: false,
      SubAffData: {
        offerId: '5466sd',
        subAff: '',
      },
      random: '',
      rules: {},
    };
  },
  mounted() {
    this.getCountry();
    this.getAdvertiserList();
    this.getList();
  },
  computed: {},
  methods: {
    // 获取列表
    getList(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      fetchTaskSupplementPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    // 获取Advertiser
    getAdvertiserList() {
      getAdvertiserList().then((res) => {
        this.options.advertiser = res.result;
      });
    },
    // 获取国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          fetchTaskSupplementDel({
            id: id,
          }).then((res) => {
            console.log(res);
            this.getList();
            this.$message({
              message: res.message,
              type: res.success ? 'success' : 'error',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    more(offerId, advertiserCode) {
      this.SubAffData.offerId = offerId;
      this.getSubAff(offerId, advertiserCode);
    },
    // 生成随机
    randomFun() {
      var len = 15;
      var $chars =
        'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      var maxPos = $chars.length;
      var pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      this.random = pwd;
    },
    // 格式化subAff
    getSubAffStr() {
      let subAffSplit = this.postData.subAff.replaceAll('\n', ',').split(',');
      let returnSubAff;
      for (let i = 0; i < subAffSplit.length; i++) {
        if (subAffSplit[i] != '' && subAffSplit[i] != 'undefined') {
          if (i == 0) {
            returnSubAff = subAffSplit[i].trim();
          } else {
            returnSubAff = returnSubAff + ',' + subAffSplit[i].trim();
          }
        }
      }
      return returnSubAff;
    },
    // 保存子渠道配置
    submitSubAff() {
      handleOfferSubAffUpdate(this.SubAffData).then((res) => {
        console.log(res);
        this.$message({
          message: '更新成功',
          type: 'success',
        });
        this.diallog.more = false;
      });
    },
    // 回显sub aff
    getSubAff(offerId, advertiserCode) {
      fetchOfferSubAff({
        offerId: offerId,
        advertiserCode: advertiserCode,
      }).then((res) => {
        console.log(res);
        this.SubAffData = res.result;
        this.SubAffData.subAff = res.result.subAff.replace(/,/g, '\n');
        this.diallog.more = true;
      });
    },
    // 开启
    start(id) {
      this.loading.startBtn = true;
      fetchTaskSupplementStart({ id: id }).then((res) => {
        console.log(res);
        this.loading.startBtn = false;
        this.$message({
          message: res.message,
          type: res.success ? 'success' : 'error',
        });
        this.getList();
      });
    },
    // 停止
    stop(id) {
      this.loading.stopBtn = true;
      fetchTaskSupplementStop({ id: id }).then((res) => {
        console.log(res);
        this.loading.stopBtn = false;
        this.$message({
          message: res.message,
          type: res.success ? 'success' : 'error',
        });
        this.getList();
      });
    },

    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
